/**
 * Web Animations `@angular/platform-browser/animations`
 * Only required if AnimationBuilder is used within the application and using IE/Edge or Safari.
 * Standard animation support in Angular DOES NOT require any polyfills (as of Angular 6.0).
 **/
import 'web-animations-js'; // Run `npm install --save web-animations-js`.
// Need to support older iOS and Android devices because Angular CLI only includes polyfills
// for ES6 features
import 'whatwg-fetch'; // Needed for AWS JS-SDK Libraries
import 'zone.js'; // Required for Angular

// necessary for aws amplify to work appropriately
// https://github.com/aws-amplify/amplify-js/issues/678#issuecomment-389106098
(window as any).global = window;
